<template>
    <div class="page">
        <div class="title">首页</div>
        <!-- <div class="title">任务</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in task" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>
        <div class="title">客户</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in client" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>
        <div class="title">订单</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in order" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>
        <div class="title">统计</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in datas" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>
        <div class="title">员工</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in staff" :key="index"> 
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>
        <div class="title">设置</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in set" :key="index"> 
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div> -->

        <template v-for="(item, index) in data">
            <div v-if="item.url != 'home' && item.url != 'set'" :key="index">
                <div class="menu">
                    <div class="title">{{ item.title }}</div>
                    <template v-for="(o, i) in item.sub">
                        <div class="meun-item" v-if="o.link" :key="i + '-' + index">
                            <router-link :to="o.link + '?title=' + o.title">
                                <img :src="o.icon" :alt="o.title">
                                <div class="item-text">{{ o.title }}</div>
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <div style="height:60px"></div>
    </div>

</template>
<script>
import { requestXml } from "../../assets/js/request";
export default {
    data() {
        return {

            task: [
                { "text": "全部任务", "url": require("@/assets/imgs/wap/task1.png"), "link": "task/taskAllList" },
                { "text": "我的新建", "url": require("@/assets/imgs/wap/xinjian.png"), "link": "task/taskList" },
                { "text": "我的待办", "url": require("@/assets/imgs/wap/task2.png"), "link": "task/taskList2" },
                { "text": "新建任务", "url": require("@/assets/imgs/wap/task3.png"), "link": "task/taskAdd" },
            ],
            client: [
                // {"text":"添加客户","url":require("@/assets/imgs/wap/client1.png"),"link":"client/clientList"},
                { "text": "全部客户", "url": require("@/assets/imgs/wap/client2.png"), "link": "client/clientList" },
                { "text": "我的客户", "url": require("@/assets/imgs/wap/client3.png"), "link": "client/clientMy" },
                { "text": "全部联系人", "url": require("@/assets/imgs/wap/lianxiren_1.png"), "link": "client/linkmanAll" },
                { "text": "我的联系人", "url": require("@/assets/imgs/wap/lianxiren.png"), "link": "client/linkmanMy" },
                { "text": "公海客户", "url": require("@/assets/imgs/wap/kehutong.png"), "link": "client/clientPublic" },
                { "text": "进件列表", "url": require("@/assets/imgs/wap/navigoumai.png"), "link": "client/purchase" },
                { "text": "添加进件", "url": require("@/assets/imgs/wap/qunfenggoumaitaocan.png"), "link": "client/addPurchase" }
            ],
            order: [
                { "text": "全部合同", "url": require("@/assets/imgs/wap/order1.png"), "link": "order/orderAllList" },
                { "text": "我的合同", "url": require("@/assets/imgs/wap/dingdan.png"), "link": "order/orderList" },
                { "text": "新建合同", "url": require("@/assets/imgs/wap/order2.png"), "link": "order/orderAdd" },
                { "text": "全部回款列表", "url": require("@/assets/imgs/wap/shoukuan_1.png"), "link": "order/paymentListBranch" },
                { "text": "回款列表", "url": require("@/assets/imgs/wap/shoukuan.png"), "link": "order/paymentList" },
                { "text": "退款列表", "url": require("@/assets/imgs/wap/order3.png"), "link": "order/refundList" },
                { "text": "全部发票", "url": require("@/assets/imgs/wap/fapiao.png"), "link": "order/invoiceList" },
                { "text": "我领取的发票", "url": require("@/assets/imgs/wap/fapiao_1.png"), "link": "order/invoiceSignList" },
                { "text": "我创建的发票", "url": require("@/assets/imgs/wap/xinjian.png"), "link": "order/invoiceCreaterList" },
                { "text": "添加发票", "url": require("@/assets/imgs/wap/tianjia.png"), "link": "order/invoiceAdd" },
            ],
            datas: [
                { "text": "经营日报", "url": require("@/assets/imgs/wap/datas1.png"), "link": "datas/daily" },
                { "text": "客户分析", "url": require("@/assets/imgs/wap/datas2.png"), "link": "datas/client" },
                { "text": "合同分析", "url": require("@/assets/imgs/wap/dingdan.png"), "link": "datas/order" },
                { "text": "目标分析", "url": require("@/assets/imgs/wap/mubiao.png"), "link": "datas/goal" },
                // {"text":"工资列表","url":require("@/assets/imgs/wap/salary.png"),"link":"datas/salary"},
            ],
            target: [
                { text: "我的目标", url: require("@/assets/imgs/wap/datas3.png"), link: "target/my" },
                // {text: "团队目标",url: require("@/assets/imgs/wap/order1.png"),link: "target/group"},
            ],
            payroll: [
                { text: "工资列表", url: require("@/assets/imgs/wap/salary.png"), link: "payroll/payrollList" },
            ],
            data: [],
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        // 获取展示列表数据
        getList() {
            requestXml("/scrm/index/getMenuMobile", "GET", (res) => {
                this.data = res
                this.data.forEach(element => {
                    if (element.url == 'home' || element.url == "set") return
                    var arr = this[element.url]
                    element.sub.forEach(item => {
                        for (var i = 0; i < arr.length; i++) {
                            if (arr[i].link.split('/')[1] == item.url) {
                                item.link = arr[i].link
                                item.icon = arr[i].url
                                item.text = arr[i].text
                            }
                        }
                    });
                });
            });
        },
    }
};
</script>
<style scoped>
.page {
    background-image: url('../../assets/imgs/wap/bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    padding-bottom: 50px;
}

.page>.title {
    line-height: 80px;
    height: 80px;
    text-align: center;
    color: #fff;
}

.menu {
    /* background: #FFFFFF; */
    background: url('../../assets//imgs//wap/box.png');
    background-size: 100% 100%;
    text-align: left;
    min-height: 200px;
    padding: 0 2%;
}

.meun-item {
    display: inline-block;
    width: 25%;
    text-align: center;
    height: 74px;
    font-size: 13px;
    color: #202020;
}

.meun-item img {
    display: inline-block;
    width: 20px;
    height: 23px;
    margin: 15px 0 5px;
    vertical-align: middle;
}

.title {
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    color: #FFFFFF;
    text-align: left;
    padding: 0 25px;
}

.item-text {
    color: #666666;
}
</style>
